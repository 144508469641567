@import "../../Sass/variables";

.forwho {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	margin-top: 30px;
	margin-bottom: 100px;
	
	&__title {
		line-height: 0.9;
		display: flex;
		flex-direction: column;
	}

	&__content {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 30px;
		max-width: 1200px;

		&-image {
			width: 80%;
			height: 200px;
		}

		&-article {
			background-color: #fff;
			opacity: 0.9;
			width: 75%;
			padding: 15px 20px;
			border-radius: 5px;
			box-shadow: 0 0 1px 1px white,
							0 0 2px 2px white,
							0 0 4px 4px white,
							0 0 8px 8px white,
						;
			margin-top: 10px;
			z-index: 100;
			font-size: 1.4rem;
			font-family: Merienda;
			text-align: center;
			color: $horse-dark;

			h4 {
				opacity: 0;
				transform: translateY(15px);
				font-size: 1.8rem;
				filter: drop-shadow(2px 2px 2px #f5c4ad);
			}

			p {
				opacity: 0;
				transform: translateY(15px);
				margin-top: 4px;
			}
		}
	}
}

@media screen and (min-width: 410px) {
	.forwho {
		margin-top: -20px;
	}
}

@media screen and (min-width: 768px) {

	.forwho {
		margin-top: 30px;

		&__title {

			h2 {
				font-size: 5.5rem;
			}
			h3 {
				font-size: 4rem;
			}
		}

		&__content {
			margin-top: 30px;

			&-image {
				height: 350px;
			}
		}

	}
}