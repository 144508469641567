.tarifs {
 margin-top: 50px;

}

.tarifs__price {
  font-size: 1.3rem;
  color: #665249;
  font-family: Merienda, cursive;
  text-align: center;
  margin: 0 20px;
}