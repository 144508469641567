@import "../../Sass/variables";

.parcours {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	margin-top: 38px;
	margin-bottom: 150px;

	&__content {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 40px;
		max-width: 1200px;

		&-image {
			width: 80%;
			height: 400px;
		}

		&-article {
			background-color: #fff;
			opacity: 0.9;
			width: 75%;
			padding: 15px 20px;
			border-radius: 5px;
			box-shadow: 0 0 1px 1px white,
							0 0 2px 2px white,
							0 0 4px 4px white,
							0 0 8px 8px white,
						;
			margin-top: 10px;
			z-index: 100;
			font-size: 1.4rem;
			font-family: Merienda;
			text-align: center;
			color: $horse-dark;
		}
	}
}

@media screen and (min-width: 410px) {
	.parcours {
		margin-top: -8px;

		&__content {
			margin-top: 37px;
		}
	}
}

@media screen and (min-width: 768px) {

	.parcours {
		
		&__title {
			margin-top: 48px;

			h2 {
				font-size: 5.5rem;
			}
		}

		&__content {
			margin-top: 40px;
			flex-direction: row;

			&-image {
				height: 700px;
				margin-left: 25px;
			}
			&-article {
				height: 700px;
				margin-right: 25px;
				margin-top: -9px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
			}
		}


	}
}