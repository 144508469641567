@import "../../Sass/variables";

.form {
	display: flex;
	flex-direction: column;
	width: 80%;
	margin-top: 16px;

	input, textarea {
		padding: 4px;
		border-radius: 5px;
		outline: none;
		margin-bottom: 15px;
		transition: all 0.3s ease;
		font-family: Merienda;
		font-size: 1.4rem;
		border: 1px solid #927a708a;

		&:hover {
			border: 1px solid transparent;
		}

		&:focus {
			box-shadow: 0 0 1px 1px white,
							0 0 3px 3px white;
			border: 1px solid transparent;
		}
	}

	label {
		font-size: 1.3rem;
	}
	
	button {
		font-size: 1.5rem;
		align-self: center;
		border-radius: 5px;
		border: none;
		padding: 5px;
		background-color: #fff;
		color: $horse-normal;
		font-family: Merienda;
		font-weight: bold;
		cursor: pointer;
		transition: all 0.3s ease;
		border: 1px solid #927a708a;

		&:focus {
		}
		
		&:hover {
			box-shadow: 0 0 1px 1px white,
							0 0 3px 3px white;
			border: 1px solid transparent;
		}
	}

	&__errorMsg {
		text-align: center;
		color: red;
		font-size: 1.1rem;
		margin-top: -13px;
	}
}

@media screen and (min-width: 768px) {
	.form {
		width: 440px;
	}
}