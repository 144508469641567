.slider {
	//max-height: 200px;
	width: 600px;
}

.gallery {

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	//margin-bottom: 150px;
	//margin-top: 38px;

    &__slider {
        height: 300px;
		width: 80%;
        margin: 38px 20px;
        position: relative;
        overflow: hidden;
        border: 2px solid #fff;
		border-radius: 10px;
		box-shadow: 0 0 1px 1px white,
						0 0 2px 2px white,
						0 0 4px 4px white,
						0 0 8px 8px white,
						0 0 15px 15px white;
    }
}

@media screen and (min-width: 410px) {
	.gallery {
		margin-top: -10px;

		&__slider {
			margin-top: 37px;
		}
	}
}

@media screen and (min-width: 768px){
	.gallery {

		&__title {
			margin-top: 50px;

			h2 {
				font-size: 5.5rem !important;
			}
		}

		&__slider {
        	height: 500px;
			margin-top: 40px;
			max-width: 960px;
		}
	}
}

.slide {
	width: 100%;
	height: 100%;
	position: absolute;
	opacity: 0;
	transition: opacity ease-in-out 0.4s;
}
.slide img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.active-anim {
  	opacity: 1;
}

.btn-slide {
	border-radius: 50%;
	background: #f1f1f1;
	border: 1px solid rgba(34, 34, 34, 0.287);
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	
	&:focus {
		//background: rgb(197, 145, 145);
		border: 1px solid rgba(255, 255, 255, 0.287);
	}
}

.big {
	width: 50px;
  	height: 50px;
}
.small {
	width: 30px;
  	height: 30px;
}

.btn-slide img {
	width: 20px;
	height: 20px;
	pointer-events: none;
}
.prev {
	top: 50%;
	left: 20px;
	transform: translateY(-60%);
}
.next {
	top: 50%;
	right: 20px;
	transform: translateY(-60%);
}


.container-dots {
	position: absolute;
	bottom: 10px;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
}
.dot {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	border: 3px solid #f1f1f1;
	margin: 0 5px;
	background: #f1f1f1;
}
.dot.active {
  	background: rgb(32, 32, 32);
}