// Utilities
@import "./Sass/variables";
@import "~react-image-gallery/styles/scss/image-gallery.scss";

.App {
	// max-height: 100vh;
	position: relative;
	// overflow-y: scroll;
	// scroll-snap-type: y proximity;
  	scroll-behavior: smooth;
	  display: flex;
	  flex-direction: column;

	.background {
		position: fixed;
		inset: 0;
		width: 100vw;
		height: 100vh;
		object-fit: cover;
		opacity: 0.9;
		z-index: -10;
		margin-bottom: 20px;
		// filter: brightness(95%)
		// opacity: 0.4;
	}

	&__imageDiv {
		position: fixed;
			left: 50px;
			top: 50px;
			height: auto;
			width: auto;
			background-color: white;
			border-radius: 50%;
			box-shadow: 0 0 1px 1px white,
						0 0 2px 2px white,
						0 0 4px 4px white,
						0 0 8px 8px white,
						0 0 15px 15px white,
						;
			transform: translate(-10%, -10%);
			z-index: 200;
	}
	&__logo {
			padding: 10px;
			height: 100px;
			
	}

	.main {
		position: relative;
		width: 100vw;
		flex: 1;
		// overflow: unset;

		&__content {
			position: absolute;
			top: 0;
			width: 100%;

		}
	}

	.hidden {
		z-index: 0;
		opacity: 0;
		max-height: 120vh;
		overflow: hidden;
		padding-bottom: 0;
		transition: all 0.8s ease;
	}
	.linkClicked {
		z-index: 0;
		opacity: 0;
		max-height: 120vh;
		overflow: hidden;
		padding-bottom: 0;
		//transition: all 1s ease;
	}

	.visible {
		z-index: 500;
		opacity: 1;
		min-height: 100vh;
		padding-bottom: 100px;
		overflow: unset;
		transition: all 0.8s ease;
	}

	.visibleFast {
		z-index: 500;
		opacity: 1;
		min-height: 100vh;
		padding-bottom: 100px;
		overflow: unset;
		//transition: all 1s ease;
	}

	.footer_div {
		display: flex;
		// position: relative;
		bottom: 0;
	}
}

@media screen and (min-width: 820px) {
	.App {

		&__imageDiv {
			left: 50px;
			top: 50px;
		}

		&__logo {
			height: 150px;
		}
	}
}