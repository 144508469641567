@import url("../../Sass/_variables.scss");

// Elements provided with the dependencie but no needed
.bm-burger-button {
	display: none;
}
.bm-cross-button {
	display: none;
}

// Elements of React Burger Menu who needed to be styles with these classes
// Sidebar wrapper styles
// Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
// */
.bm-menu-wrap {
	position: fixed;
	height: 100%;
	z-index: 10;
}

// General sidebar styles 
.bm-menu {
	background: #fff;
	top: 0;
}
// Sidebar items
.bm-item-list {
	height: 300px;
	margin-top: 40px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	a:visited {
		color: #665249;
	}
}

.head {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	position: relative;
	width: 100%;
	height: 180px;

	&__layout {
		position: absolute;
		z-index: 0;
		top: 55%;
		width: 100%;
		height: 35%;
		transform: translate(0, -65%);
		background-color: #fff;
		box-shadow: 0 10px 20px 10px #fff,
					0 -10px 20px 10px #fff
		;
	}

	&__brand {
		width: 100%;
		height: 100%;
		max-width: 1400px;
		z-index: 1;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		position: relative;


		&__name {
			margin-right: 15px;

			&-title {
				font-size: 4rem;
				color: #665249;
				text-align: center;
				line-height: 1.2;
				font-family: The Nautigal;
				filter: drop-shadow(2px 2px 2px #f5c4ad);
				width: 100%;
			}

			&-subtitle {
				font-size: 3rem;
				position: absolute;
				left: 50%;
				transform: translate(-50%, 0);
				top: 170px;
				width: max-content;
				color: #665249;
				text-align: center;
				line-height: 1.2;
				font-family: The Nautigal;
				filter: drop-shadow(2px 2px 2px #f5c4ad);
			}
		}

		&-logoDiv {
			padding: 5px;
			margin-left: 15px;
			margin-top: 10px;
			background-color: white;
			border-radius: 50%;
			box-shadow: 0 0 1px 1px white,
						0 0 2px 2px white,
						0 0 4px 4px white,
						0 0 8px 8px white,
						0 0 15px 15px white,
					;
		
			&-logo {
				height: 120px;
				margin-right: 10px;
			}
		}
	}
}

@media screen and (min-width: 410px) {
	.head {
		&__brand {
			&__name {
				width: 100%;
				margin-right: 10px;

				&-subtitle {
					top: 100px;
					position: static;
					transform: translate(0, 0);
					width: auto;
					margin-left: 0;
					font-size: 3.2rem;
				}
			}
		}
			
	}
}

@media screen and (min-width: 500px) {
	.head {
		&__brand {
			&__name {
				&-title {
					font-size: 5rem;
				}
			}

			&-logoDiv {
				margin-left: 30px;

				&-logo {
					height: 140px;
				}
			}
		}
			
	}
}

@media screen and (min-width: 768px) {
	.head {
		height: 180px;
		margin-top: 30px;

		&__layout {
			height: 50%;
		}

		&__brand {
			flex-direction: row;
			justify-content: space-between;

			&__name {

				&-title {
					width: 40%;
					margin-left: 20px;
					margin-right: 20px;
					line-height: 1;
				}

				&-subtitle {
					width: 40%;
					font-size: 3.5rem;
					margin-right: 20px;
					line-height: 1;
				}
			}

			&-logoDiv {
				position: static;
				margin-left: 0;

				&-logo {
					height: 160px;
				}
			}
		}
			
	}
}

@media screen and (min-width: 999px) {
	.head {
		&__layout {
			height: 70px;
		}
		&__brand {
			&__name {
				&-title {
					font-size: 6rem;
				}

				&-subtitle {
					font-size: 4rem;	
				}
			}
		}
			
	}
}
@media screen and (min-width: 1350px) {
	.head {
		&__brand {
			&__name {
				&-title {
					font-size: 8rem;
				}

				&-subtitle {
					font-size: 5rem;	
				}
			}
		}
			
	}
}