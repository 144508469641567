@import "../../Sass/variables";

.title {

	color: #ffffff;
	filter: drop-shadow(0px 0px 5px #755243);
	font-weight: 700;
	font-family: The Nautigal;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 100;

		h2 {
			width: fit-content;
			margin-right: 10px;
			font-size: 5.5rem;
		}
		h3 {
			width: fit-content;
			margin-left: 10px;
			font-size: 4rem;
		}
}


@media screen and (min-width: 768px) {
	.title {
	}
}