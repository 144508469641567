.image {
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
	box-shadow: 0 0 1px 1px white,
							0 0 2px 2px white,
							0 0 4px 4px white,
							0 0 8px 8px white,
							0 0 15px 15px white,
						;

	img {
		min-width: 100%;
		height: 100%;
		object-fit: cover;
		z-index: 10;
	}
}

//.parcours {
//	border-bottom-left-radius: 10px;
//	border-top-left-radius: 10px;
//}
//
//.other {
//	border-radius: 10px;
//}

@media screen and (min-width: 768px) {
	.image {
		border: 2px solid #fff;
		border-radius: 10px;

		img {
			min-width: 100%;
			height: 120%;
			filter: brightness(100%);
			transform: translateY(20px);
		}
	}
}