.mobileNav {

	&__button {
		z-index: 1500;
		position: fixed;
		top: -2px;
		right: -2px;
		transition: all 0.4s ease;
	} 

	&__menu {
		top: 0;
		z-index: 600;

		&-overlay {
			top: 0;
			left: 0;
		}

		h2 {
			margin-bottom: 40px;
			margin-top: 40px;
		}

		&-link {
			color: #665249;
			height: 50px;
			font-size: 3rem;
			font-weight: bold;
			font-family: Dancing Script;
			display: flex;
			justify-content: center;
			align-items: center;
			vertical-align: middle;
			transition: all 0.3s ease;
			filter: drop-shadow(2px 2px 2px #f5c4ad);
			text-decoration: none;
			cursor: pointer;
		}
	}
}