@import "../../Sass/variables";

.contact {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	z-index: 250;
	//margin-top: 38px;
	margin-bottom: 100px;

	
	&__infos {
		font-family: Merienda;
		font-size: 1.4rem;
		width: 80%;
		color: $horse-dark;
		margin-top: 30px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		text-align: center;

		&-tel {
			color: $horse-normal;
			font-size: 2.5rem;
			margin-top: 10px;
			margin-bottom: 6px;
			transition: all 0.3s ease;

			&:hover {
				transform: scale(1.1);
			}
		}
	}

	&__place {
		font-family: The Nautigal;
		margin-bottom: 20px;
		margin-top: 20px;

		h2 {
			font-size: 4rem;
		}
	}

	&__adress {
		font-family: Merienda;
		font-size: 1.5rem;
		text-align: center;
		margin: 0 20px;

	}
}

@media screen and (min-width: 410px) {
	.contact {
		margin-top: -10px;
	}
}

@media screen and (min-width: 768px) {

	.contact {

		&__title {
			margin-top: 50px;

			h2 {
				font-size: 5.5rem;
			}
		}

		&__place {

			h2 {
				font-size: 5.5rem;
			}
		}

		&__content {
			margin-top: 30px;

			&-image {
				height: 350px;
			}
		}

	}
}