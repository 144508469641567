.desktopNav {
	margin-top: 50px;
	background-color: #fff;
	box-shadow: 0 0px 20px 20px #fff;
	width: fit-content;
	position: relative;
	left: 50%;
	transform: translate(-50%, 0);
	display: flex;
	flex-direction: row;
	align-items: center;
	z-index: 100;
	height: 40px;
	border-radius: 10px;

	&__link {
		color: #665249;
		z-index: 2000;
		font-size: 2.2rem;
		font-weight: bold;
		font-family: Dancing Script;
		margin-left: 20px;
		margin-right: 20px;
		transition: all 0.2s ease;
		filter: drop-shadow(2px 2px 2px #f5c4ad);
		cursor: pointer;

		&:hover {
			transform: translateY(-2px);
			filter: drop-shadow(1px 1px 1px #f5c4ad);
		}
	}
}

@media screen and (min-width: 768px) {
	.desktopNav {
		
		&__link {
			font-size: 2.6rem;
		}
	}
}